export const reasons = [
    {
        image: "/vpnpricecomparison/assets/images/connect.png",
        title: "Be wherever you want to be",
        description:
            "Access servers spanning nearly 100 countries worldwide and hide your true location from online services.",
    },
    {
        image: "/vpnpricecomparison/assets/images/access.png",
        title: "Access everything",
        description:
            "Empower your digital experience with VPNs. Seamlessly access content from around the globe and enjoy the freedom to browse, stream, and download without borders.",
    },
    {
        image: "/vpnpricecomparison/assets/images/anonymous.png",
        title: "Enjoy true online anonymity",
        description:
            "Surf the web and download files without the fear of being traced or monitored. Your anonymity is guaranteed With top-tier VPNs.",
    },
];
