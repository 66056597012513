import React, { ReactElement } from "react";
import { reasons } from "@lib/vpnpricecomparison/usageReasons";
import Image from "next/image";

export default function UsageReasons(): ReactElement {
    return (
        <section className="mb-14 max-w-xl md:max-w-3xl lg:max-w-7xl mx-auto md:text-center lg:text-left">
            <h2 className="text-xl md:text-2xl font-bold mb-5">
                Why use VPN Services
            </h2>
            <div className="lg:flex lg:justify-between lg:children:px-3 lg:children:first:pr-6 lg:children:first:pl-0 lg:children:last:pl-6 lg:children:last:pr-0">
                {reasons.map((reason, index) => (
                    <div
                        key={index}
                        className="mb-10 lg:w-1/3 md:max-w-xl md:mx-auto"
                    >
                        <div className="w-fill">
                            <Image
                                layout="intrinsic"
                                src={reason.image}
                                alt={reason.title}
                                width={392}
                                height={190}
                            />
                        </div>
                        <h3 className="mt-3 font-medium text-lg">
                            {reason.title}
                        </h3>
                        <p className="text-sm mt-1 font-light">
                            {reason.description}
                        </p>
                    </div>
                ))}
            </div>
        </section>
    );
}
