import React, { ReactElement } from "react";
import dynamic from "next/dynamic";

import { GetServerSideProps } from "next";
import UsageReasons from "@components/vpnpricecomparison/usageReasons";

import MainSection from "@components/vpnpricecomparison/mainSection";

import { VpnPriceComparisonWrapper } from "@components/layouts";
import { getPageProps } from "@services/initial-calls";
const DefaultOffers = dynamic(
    () => import("@components/vpnpricecomparison/defaultOffers"),
);
const WeStand = dynamic(() => import("@components/vpnpricecomparison/weStand"));

const Home = (): ReactElement => {
    return (
        <VpnPriceComparisonWrapper>
            <MainSection />
            <UsageReasons />
            <DefaultOffers />
            <WeStand />
        </VpnPriceComparisonWrapper>
    );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
